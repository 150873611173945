.nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--mainColor);
    color: white;
    height: 200px;
    z-index: 999;
}

.image-logo-header{
    text-align: center;
    width: 200px;

}
.image-logo-header img {
    max-height: 100px;
    max-width: 200px;

}

.logo {
    text-align: center;
}

.logo h1 {
    font-size: 40px;
    margin: 0 8px;
}

.language {
    border: 1px white solid;
    padding: 15px 13px;
    border-radius: 50%;
}

.language:hover {
    color: var(--mainColor);
    background-color: white;
    font-weight: bold;
}

.chosen {
    color: #373737;
    background-color: white;
}

.first-row {
    box-sizing: border-box;
    height: 73px;
    width: 100%;
    /* background-color: #fff; */
    /* color: #404040; */
    /* border-top: 5px solid #ffec70; */
}

.first-row, .second-row {
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* width: 60%; */
    /* margin-top: 20px; */
    position: fixed;
}

.second-row {
    width: 60%;
    top: 137px;
}

.second-row ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
}

.second-row ul.menu, .second-row ul.submenu {
    padding: 0;
    margin: 0px;
}
.second-row ul.submenu{
    width: 300px;
    padding: 35px 20px;
    border: 1px solid #999;
}


.second-row ul li .link-style {
    /* border-right: 1px solid rgba(255, 255, 255, 0.24); */
    white-space: nowrap;
    text-decoration: none;
    font-size: 20px;
    color: white;
    padding: 17px 20px 12px 20px;
    display: block;
    text-align: center;
    box-sizing: border-box;
    border-top: 5px solid transparent;
}

.curserDefault{
    cursor: default;
}

.curserPointer{
    cursor: pointer;
}

.second-row ul li .link-style:hover {
    /* border-top: 5px solid #ffec70; */
    background: rgba(255, 255, 255, 0.09)
    /* background-color:  var(--secondColor); */
}

.second-row ul li ul li .link-style {
    padding: 10px 0px;
    height: auto;
    border: none;
    border-bottom: 1px solid #ddd;
    text-align: left;
    width: 300px;
    font-size: 20px;
    color: #666;
}

.second-row ul li ul li .link-style:hover{
    color: #1fa6d9;
    border: none;
    border-bottom: 1px solid #ddd;
    background-color: #FCFCFC;
    /* font-weight: bold; */
    z-index: 2;
}

.second-row ul li ul {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: #FCFCFC;
}

.second-row ul li ul li {
    width: 190px;
    position: relative;
}

.second-row nav ul li:hover > ul {
    display: block;
}

.nav ul {
    list-style: none;
    display: flex;
}

.nav ul li ul {
    display: flex;
    flex-direction: column;
    background-color: #FCFCFC;
}

.nav ul li ul li {
    width: 300px;
    position: relative;
}

.nav ul li ul li ul {
    top: 0;
    left: 180px;
    position: absolute;
}

.nav .second-row ul li ul {
    display: none;
}

.nav .second-row ul li:hover > ul {
    display: flex;
}

.footer {
    height: 50px;
    position: relative;
    bottom: 0;
    width: 100%;
    background-color: #FCFCFC;
    display: flex;
    align-items: center;
    justify-content:space-around;
    font-size: 20px;
}
.footer p{
    margin-left: 30%;
}

.image {
    color: var(--mainColor);
    text-decoration: none;
}


/* < ANIMATION > */

.nav .second-row > ul li a, .nav .second-row > ul li span {
    box-sizing: border-box;
    height: 63px;
	color:#fff;
	display:block;
	position: relative;
	text-align:center;
	text-decoration:none;
	/* -webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-ms-transition: all .3s ease;
	-o-transition: all .3s ease; */
	/* transition: all .3s ease; */
}

.nav .second-row > ul.submenu li a, .nav .second-row > ul li span {
	color:#fff;
	display:block;
	position: relative;
	text-align:center;
	text-decoration:none;
	/* -webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-ms-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease; */
}

/* </ ANIMATION > */


/* < RESPONSIVE > */

/* NAV */

/* first-row */
@media (max-width:1400px) {
    .nav {
    }
    .first-row {
        /* flex-direction: column; */
        /* position: absolute; */
        /* top: 0; */

    }

    .first-row .logo {
        /* margin-bottom: 20px; */
    }
    .second-row {
        /* margin-top: 70px; */
    }

    .body, .prompt-body, .settings-body {
        /* margin-top: 250px !important; */
    }
}

/* second-row */
.hamburger {
    display: none;
    cursor: pointer;
}

.bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: white;
}

@media (max-width:865px) {
    .image-logo-header img {
        margin-bottom: 5px;
        max-height: 80px;
        max-width: 200px;
    }
    .first-row{
        flex-direction: column;
        /* margin-top: -60px*/
        height: 130px;
    }
    .nav {
        height: 220px;
    }
    .body, .prompt-body, .settings-body {
        margin-top: 200px !important;
    }  
    .second-row {
        margin-top: 34px;
    }
    .hamburger {
        margin-top: 10px;
        display: block;
    }

    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }
    .hamburger.active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }
    .hamburger.active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }

    .nav-menu {
        position: fixed;
        left: -100%;
        top: 180px;
        gap: 0;
        flex-direction: column;
        background-color: var(--mainColor);
        width: 100%;
        text-align: center;
        transition: 0.3s;
    }

    .nav-item {
        margin: 16px 0;
    }

    .nav-menu.active {
        left: 0;
        top: 200px;
    }

    ul.submenu {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 998;
    }

    ul.submenu li ul.submenu {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 44px;
        left: 0;
    }

    .language {
        display: none;
    }
}

/* @media screen and (max-width: 400px) {
    .nav-item  {
        height: 30px;
    }
    .nav-menu.active {
        top: 120px;
    }
} */

/* FOOTER */

@media screen and (max-width: 600px) {
    .footer {
        flex-direction: column;
    }
}

/* </ RESPONSIVE > */